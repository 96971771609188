import { useEffect, useRef, useState } from "react";
import Drop from "./Drop";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";
import { blobToURL } from "./utils/Utils";
import PagingControl from "./components/PagingControl";
import { AddSigDialog } from "./components/AddSigDialog";
import { Header } from "./Header";
import { BigButton } from "./components/BigButton";
import DraggableSignature from "./components/DraggableSignature";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Button } from "rsuite";
import Swal from "sweetalert2";
import { SignSigDocument } from "../../ApiServices/dashboardHttpService/dashboardHttpServices";
import { useNavigate } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const PdfSignCanvas = () => {
  const styles = {
    container: {
      maxWidth: 808,
      margin: "0 auto",
      // border: "1px solid #999",
    },
    sigBlock: {
      display: "inline-block",
      // border: "1px solid #000",
    },
    documentBlock: {
      maxWidth: 808,
      padding: "20px 0",
      border: "1px solid black",
      borderRadius: "5px",
    },
    controls: {
      maxWidth: 808,
      // background: "#ebf5ed",
      background: "white",
      // border: "1px solid black",
      borderBottom: "0px",
      marginTop: 0,
      borderRadius: "5px",
      padding: "10px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  };
  const [pdf, setPdf] = useState(null);
  const [autoDate, setAutoDate] = useState(true);
  const [signatureURL, setSignatureURL] = useState(null);
  const [position, setPosition] = useState(null);
  const [signatureDialogVisible, setSignatureDialogVisible] = useState(false);
  const [textInputVisible, setTextInputVisible] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState(null);
  const documentRef = useRef(null);
  const [isSigned, setIsSigned] = useState(false);
  const [pdfAfterSign, setPdfAfterSign] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const signatoryDocuments = useSelector(
    (state) => state?.employeeDoc?.signatoryDocs
  );

  useEffect(() => {
    const handleResizeOrScroll = () => setPosition((prev) => ({ ...prev }));

    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
    };
  }, []);

  const addSignatureToPDF = async () => {
    let ip;
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      ip = (await response.json())?.ip;
    } catch (error) {
      console.log(error);
    }

    if (!position || !documentRef.current) return;

    const { originalHeight, originalWidth } = pageDetails;
    const rect = documentRef.current.getBoundingClientRect();
    const scale = originalWidth / rect.width;
    const xOffset = 150;
    const yOffset = 10;

    let y =
      documentRef.current.clientHeight -
      (position.y + 12 * scale - position.offsetY - rect.top) -
      yOffset;
    let x = position.x - rect.left - xOffset;

    const signatureWidth = 10;
    const signatureHeight = 50;
    const maxX = originalWidth - signatureWidth * scale;
    const maxY = originalHeight - signatureHeight * scale;

    x = Math.max(0, Math.min(x, maxX));
    y = Math.max(0, Math.min(y, maxY));

    const newY = (y * originalHeight) / rect.height;
    const newX = (x * originalWidth) / rect.width;

    const pdfDoc = await PDFDocument.load(pdf);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const pngImage = await pdfDoc.embedPng(signatureURL);
    const pngDims = pngImage.scale(scale * 0.3);

    firstPage.drawImage(pngImage, {
      x: newX,
      y: newY,
      width: pngDims.width,
      height: pngDims.height,
    });
    const centerX = newX + pngDims.width / 2;

    if (true) {
      const timestamp = dayjs().format("MM/DD/YYYY HH:mm:ss");

      firstPage.drawText(timestamp, {
        x: centerX - timestamp.length * 2.5,
        y: newY - 8,
        size: 7 * scale,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(ip, {
        x: centerX - ip.length * 2.5,
        y: newY - 15,
        size: 7 * scale,
        color: rgb(0, 0, 0),
      });
    }

    setIsSigned(true);
    const pdfBytes = await pdfDoc.save();
    setPdfAfterSign(pdfBytes);
    setPdf(await blobToURL(new Blob([new Uint8Array(pdfBytes)])));
    setPosition(null);
    setSignatureURL(null);
  };

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        let pdf = localStorage.getItem("empDoc")
        const response = await fetch(pdf);
        if (!response.ok) {
          throw new Error("Failed to fetch PDF");
        }
        const blob = await response.blob();
        const URL = await blobToURL(blob);
        setPdf(URL);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    if (signatoryDocuments?.docs[0]) {
      fetchPDF();
    }
  }, [signatoryDocuments?.docs[0]]);

  const handleSignDone = async () => {
    const formData = new FormData();
    formData.append("document_Id", signatoryDocuments?._id);
    formData.append("templete_Id", signatoryDocuments?.templete_Id);
    formData.append(
      "templete",
      new Blob([pdfAfterSign], { type: "application/pdf" }),
      "updated.pdf"
    );
    try {
      setLoader(true);

      let { data } = await SignSigDocument(formData);
      if (data && !data?.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Document Signed",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        navigate("/Signatory/Requests");
        localStorage.setItem("isRead", false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <Header />
      <div style={styles.container}>
        {signatureDialogVisible ? (
          <AddSigDialog
            autoDate={autoDate}
            setAutoDate={setAutoDate}
            onClose={() => setSignatureDialogVisible(false)}
            onConfirm={(url) => {
              setSignatureURL(url);
              setSignatureDialogVisible(false);
            }}
          />
        ) : null}
        {pdf ? (
          <div>
            <div style={styles.controls}>
              <div className="d-flex">
                {!signatureURL ? (
                  <BigButton
                  disabled={isSigned}
                    title={"Add signature"}
                    onClick={() => setSignatureDialogVisible(true)}
                  />
                ) : null}

                <Button
                  appearance="primary"
                  color="blue"
                  className="px-5 py-2 ms-3"
                  disabled={!isSigned}
                  onClick={handleSignDone}
                >
                  Submit
                </Button>

                <Button
                  appearance="primary"
                  color="red"
                  className="px-5 py-2 ms-3"
                  disabled={!isSigned}
                  onClick={() => window.location.reload()}
                >
                  Reset
                </Button>
              </div>

              <PagingControl
                pageNum={pageNum}
                setPageNum={setPageNum}
                totalPages={totalPages}
              />
            </div>
            <div
              ref={documentRef}
              style={{ ...styles.documentBlock, overflow: "auto" }}
            >
              {signatureURL ? (
                <DraggableSignature
                  url={signatureURL}
                  onCancel={() => {
                    setSignatureURL(null);
                  }}
                  onSet={addSignatureToPDF}
                  onEnd={setPosition}
                />
              ) : null}
              <Document
                file={pdf}
                onLoadSuccess={(data) => {
                  setTotalPages(data.numPages);
                }}
              >
                <Page
                  pageNumber={pageNum + 1}
                  width={800}
                  height={1200}
                  onLoadSuccess={(data) => {
                    setPageDetails(data);
                  }}
                />
              </Document>
            </div>
            {/* <PagingControl
              pageNum={pageNum}
              setPageNum={setPageNum}
              totalPages={totalPages}
            /> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PdfSignCanvas;
